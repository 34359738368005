import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { LoginHelperService } from "../../shared/services/login-helper.service";
import { PermissionService } from "../../shared/services/permission.service";
import {PlanHelperService} from "../../shared/services/plan-helper.service";

@Injectable({
    providedIn: 'root'
})
export class CollectionsViewableGuard  {
  
    constructor(
        private planHelperService: PlanHelperService,
        private router: Router,
        private loginHelperService: LoginHelperService,
        private permissionService: PermissionService,
    ) {

    }

    async canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<boolean> {
        try {
            if (!this.loginHelperService.isUserLoggedIn()) {
                this.router.navigate([environment.globals.welcomeAppPage]);
                return false;
            }
            if (!this.permissionService.couldEditAll() && !await this.planHelperService.hasCollectionsFeature()) {
                this.router.navigate([environment.globals.firstAppPage]);
                return false;
            }
    
            return true;
            
        } catch (error) {
            console.error(error);
            this.router.navigate([environment.globals.firstAppPage]);
            return false;
        }
    }
    
}
