
export const BASE_PATH = "admin";

export enum AdminModuleRoute {
    HOME = "",
    USERS = "users",
    SINGLE_USER = "user/:userId",
    ORGANIZATIONS = "organizations",
    COUPONS = "coupons",
    PRIVATE_ASSETS = "assets",
    TASK_LIST = "events",
    SUDO = "sudo-list",
    APPROVALS = "approval",
    COLLECTIONS = "collections",
    UPLOADS = "uploads",
    TOPICS = "topics",
    COURSES = "courses",
    WORKSHOPS = "workshops",
    EXPERTS = "experts",
    DEFAULT_STORIES = "stories",
    VIDEO_POPUPS = "video-popups",
    MARKETING_TASKS = "emails",
    EMAIL_TEMPLATES = "email-templates",
    SCHEDULES = "scheduled-jobs",
    CRON_JOBS = "cron-jobs",
    NOTIFICATIONS = "notifications",
    VERSION_CONFIG = "version-config",
    VIDEO_FEED = "video-feed",
    DATA_PIPELINES = "data-pipelines",
}
